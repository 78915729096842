import { t } from '../languages';
import service from "./service.js";
import request from "./http.js";

const data = {
  //获取图层表格数据
  getLayers: (params) => {
    return service.get({
      url: "/api/v1/layers",
      params
    })
  },
  // 获取全部图层数据
  getAllLayers: () => {
    return service.get({
      url: "/api/v1/layers/all",
    })
  },
  // 修改图层默认显示方式
  updateLayerDisplay: (layerId, params) => {
    return request({
      url: `/api/v1/layers/${layerId}/displayState`,
      method: "put",
      params: params
    });
  },
  //获取筛选下拉文件列表
  getDropdown: () => {
    return service.get({
      url: "/api/v1/layer/types/dropdown"
    })
  },
  //获取图层详情
  getLayerDetails: (layerId) => {
    return service.get({
      url: `/api/v1/layer/details/${layerId}`
    })
  },
  //批量删除详情
  removeLayers: (data) => {
    return service.delete({
      url: '/api/v1/layers/remove',
      data
    })
  },



  //保存成果信息
  saveLayer: (url, data) => {
    return service.post({
      url,
      data,
      isFormData: true
    })
  },
  //成果导入
  importLayers: (url, data) => {
    return service.longPost({
      url,
      data,
      isFormData: true,
      timeout: 5
    })
  },
  //获取巡检数据列表
  getPatrolTable: (params) => {
    return service.get({
      url: '/api/v1/inspection/data/list',
      params
    })
  },
  //获取巡检数据来源下拉列表
  getTypesDropdown: () => {
    return service.get({
      url: '/api/v1/data/source/types/dropdown'
    })
  },
  //获取机场、飞行器、人员下拉列表
  getSourceDropdown: (params) => {
    return service.get({
      url: '/api/v1/data/source/dropdown',
      params
    })
  },
  //获取导入类型下拉列表
  getImportDropdown: () => {
    return service.get({
      url: '/api/v1/data/types/dropdown'
    })
  },
  //获取文件夹索引树
  getFileThree: (folderId) => {
    return service.get({
      url: `/api/v1/folder/tree/${folderId}`
    })
  },
  //批量删除巡检数据
  removeDatas: (url, data) => {
    return service.delete({
      url,
      data
    })
  },
  //粘贴数据
  pasteDatas: (folderId, data) => {
    return service.post({
      url: `/api/v1/data/paste/${folderId}`,
      data
    })
  },
  //重命名
  dataRename: (url) => {
    return service.put({
      url
    })
  },
  //获取版本库列表
  getVersionTable: (params) => {
    return service.get({
      url: '/api/v1/show/version/list',
      params
    })
  },
  //版本库批量删除
  deleteVersionData: (data) => {
    return service.delete({
      url: '/api/v1/delete/version',
      data
    })
  },
  //查询类型下拉列表
  getTypeList: () => {
    return service.get({
      url: '/api/v1/show/version/downList',
    })
  },
  //版本库分块导入
  importVersion: (data) => {
    return service.longPost({
      url: '/api/v1/upload/version/chunk',
      data,
      isFormData: true,
      timeout: 3
    })
  },
  //版本库数据保存
  saveVersion: (data) => {
    return service.post({
      url: '/api/v1/add/version',
      data,
    })
  },
  //切换巡检数据类型
  updatePhototype: (id, dataType) => {
    return service.post({
      url: `/api/v1/data/photo/type/${id}?photoType=${dataType}`,
    })
  },
  //获取成果的folder头id
  getFolderHeadId: () => {
    return service.get({
      url: '/api/v1/result/type'
    })
  },
  //创建文件夹
  createFolder: (folderName, pid) => {
    return service.post({
      url: `/api/v1/folder/save/?folderName=${folderName}&pid=${pid}`,
    })
  },
  //删除图层文件夹
  removeLayersFolder: (data) => {
    return service.delete({
      url: '/api/v1/folders/remove',
      data
    })
  },
  //获取机场的暂停继续状态
  getStatus: (sn) => {
    return service.get({
      url: `/api/v1/operate/status/${sn}`
    })
  },
}
export default data