import { t } from '../../../../languages';

import { reactive, ref, onMounted } from "vue";
import ufi3d from "@/utils/ufi-cesium";
import data from "@/network/data";
import { ElMessage } from "element-plus";
export function useTif(initCesium) {
    const state = reactive({
        layarList: [], //图层列表
        filterLayerList: [], //图层筛选列表
    });
    let viewer
    onMounted(() => {
        viewer = initCesium()
        // console.log(viewer.value);
    })
    const getAllLayers = async () => {
        const res = await data.getAllLayers();
        if (res.resultStatus) {
            const layarData = res.resultData;
            for (let i = 0; i < layarData.length; i++) {
                const item = layarData[i];
                item.displayStatus = false;
            }
            state.layarList = layarData;
            state.filterLayerList = state.layarList;
        }
    };
    let customImagery = null; //tif影像对象
    let pointCloud = null; //点云对象
    /**
     * 添加影像图
     * @param {* 影像图id} id
     * @param {* 影像图地址} addr
     * @param {* 影像图元信息} tifInfo
     */
    function addImagery(id, addr, tifInfo, isZoomTo) {
        if (!customImagery) {
            customImagery = new ufi3d.CustomImagery(viewer);
        }
        customImagery.addImagery(id, addr, tifInfo, isZoomTo);
    }
    /**
     * 定位影像图
     * @param {* 影像图id} id
     */
    function zoomToImagery(id) {
        customImagery.zoomToImagery(id);
    }
    function zoomTo3DTiles(id) {
        pointCloud.jumpCloudPoint(id);
    }
    /**
     * 切换影像图显示方式
     * @param {* 影像图id} id
     * @param {* 是否显示} flag
     */
    function toggleImagery(id, flag) {
        customImagery.showHideImagery(id, flag);
    }
    /**
     * 切换图层显示方式
     * @param {* 图层id} id
     * @param {* 是否显示} flag
     */
    function toggle3DTiles(id, flag) {
        pointCloud.showHidePointCloud(id, flag);
    }
    /**
     * 添加图层
     * @param {* 图层id} id
     * @param {* 图层地址} addr
     * @param {* 是否定位} displayFlag
     */
    function add3DTiles(id, addr, displayFlag) {
        if (!pointCloud) {
            pointCloud = new ufi3d.PointCloud(viewer);
        }
        pointCloud.addPointCloud(id, addr, 0, displayFlag);
    }

    const visibleLayar = []; //已经实例化过的图层列表
    /* 切换图层显示 */
    function changeLayarVisible(item) {
        const layerId = item.layerId;
        const isCheck = item.displayStatus;
        const layerUrl = item.layerUrl;
        const layerType = item.layerType;
        if (visibleLayar.includes(layerId)) {
            // 选中过
            if (layerType === "TIF") {
                toggleImagery(layerId, isCheck);
            } else {
                toggle3DTiles(layerId, isCheck);
            }
        } else {
            // 没有选中过
            visibleLayar.push(layerId);
            // 初始化点云、b3dms或tif
            if (layerType === "TIF") {
                addImagery(layerId, layerUrl, item.tifMetaInfo, true);
            } else {
                add3DTiles(layerId, layerUrl, true);
            }
        }
    }
    /* 定位图层 */
    function zoomToLayer(item) {
        const layerId = item.layerId;
        if (visibleLayar.includes(layerId)) {
            if (item.layerType === "TIF") {
                zoomToImagery(layerId);
            } else {
                zoomTo3DTiles(layerId);
            }
        } else {
            ElMessage.warning(t("148"));
        }
    }

    const layerFilterWord = ref(""); //筛选图层关键词
    /* 筛选图层列表 */
    function filterLayer() {
        state.filterLayerList = state.layarList.filter((item) => {
            if (item.layerName.indexOf(layerFilterWord.value) !== -1) {
                return item;
            }
        });
    }

    /* 筛选图层输入框改变 */
    function layerChange() {
        if (layerFilterWord.value === "") {
            state.filterLayerList = state.layarList;
        }
    }
    return {
        getAllLayers,
        changeLayarVisible,
        filterLayer,
        layerChange,
        zoomToLayer,
        state,
        layerFilterWord,
    }
}