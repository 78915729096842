import { t } from '../languages';
import axios from 'axios'
import {  ElNotification } from 'element-plus';
// 系统文件导出方法封装;url:地址，params：请求参数，type：文件类型字符串,默认excel表

function downloadSource(
  url = "",
  params = {},
  type = 'application/vnd.ms-excel',
  ajaxMethod = 'get',
  notification = ElNotification({
    title: t('1'),
    message: t('2'),
    duration: 0,
    customClass: "notifyStyles",
  })
) {
  return new Promise((resolve, reject) => {
    let paramObj = {};
    if (ajaxMethod === 'get') {
      paramObj = {
        url: process.env.VUE_APP_BASE_API + `${url}`, //请求地址
        method: ajaxMethod,
        params: params,
        headers: {
          token: localStorage.getItem('token') //token给后端验证，看自己项目
        },
        responseType: 'blob'
      }
    } else if (ajaxMethod === 'post') {
      paramObj = {
        url: process.env.VUE_APP_BASE_API + `${url}`, //请求地址
        method: ajaxMethod,
        data: params,
        headers: {
          token: localStorage.getItem('token') //token给后端验证，看自己项目
        },
        responseType: 'blob'
      }
    }
    axios(paramObj).then(res => {
      resolve(res)
      notification.close();
      if (res.status !== 201) {
        const blob = new Blob([res.data], {
          type: `${type};charset=utf-8`
        })
        let thename = '';
        const contentDisposition = res.headers['content-disposition'] //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        if (contentDisposition) {
          const thearr = contentDisposition.split(
            'filename=',
            contentDisposition.length
          )
          if (thearr.length > 1) {
            //确保获取到'filename='后面的字符串
            thename = decodeURIComponent(thearr[1]) //使用decodeURI对名字进行解码
          } else {
            thename = t('3')
          }
        }
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = thename //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    }).catch((res) => {
      reject(res)
      notification.close();
    })
  })
}

export default {
  downloadSource
}
