<template>
  <div id="cesiumContainer">
    <!-- 图层模块 -->
    <div class="layer_div">
      <div class="codeImg">
        <div class="elsearch flex-a">
          <el-input
            class="filePut"
            type="text"
            :placeholder="t('286')"
            clearable
            v-model="layerFilterWord"
            @change="layerChange"
          />
          <div class="file_icon flex-fac" @click="filterLayer">
            <el-icon style="cursor: pointer">
              <Search />
            </el-icon>
          </div>
        </div>


        <div class="laylist">
          <div
            class="flex flex-sb itemTop"
            v-for="item in state.filterLayerList"
            :key="item.layerId"
            @click.self="zoomToLayer(item)"
          >
            <span :title="item.layerName" class="overflow_text">{{ item.layerName }}</span>
            <el-checkbox
              v-model="item.displayStatus"
              @change="changeLayarVisible(item)"
            ></el-checkbox>
          </div>
        </div>
      </div>
    </div>
        <!-- 航线信息模块 -->
    <div class="route_message">
      <p class="title flex-fac">
        <span class="span-wid"> {{previewType === "route"
            ? t("1184")
            : t("1185") }}</span>
        <span class="overflow_text" :title="routeData.taskName">{{routeData.taskName}}</span>
      </p>
      <ul class="message_box" v-if="previewType === 'route'">
        <li class="message_item overflow_text" :title="routeData.name">{{ t('400') }}: {{ routeData.name }}</li>
        <li class="message_item">{{ t('1186') }}: {{ routeData.totalPoints }}</li>
        <li class="message_item">{{ t('1187') }}: {{ routeData.photoPoints }}</li>
        <li class="message_item">
          {{ t('1188') }}:
          {{
            routeData && routeData.airwayLength ? routeData.airwayLength : "-"
          }}m
        </li>
        <li class="message_item">
          {{ t('1189') }}: {{ routeData.maxFlightSpeed }}m/s
        </li>
      </ul>
      <ul class="message_box" v-else>
        <li class="message_item overflow_text" :title="routeData.name">{{ t('400') }}: {{ routeData.name }}</li>
        <li class="message_item">{{ t('1186') }}: {{ routeData.totalPoints }}</li>
        <li class="message_item">{{ t('1187') }}: {{ routeData.photoPoints }}</li>
        <li class="message_item">
          {{ t('1188') }}:
          {{
            routeData && routeData.airwayLength ? routeData.airwayLength : "-"
          }}m
        </li>
        <li class="message_item">
          {{ t('1189') }}: {{ routeData.maxFlightSpeed }}m/s
        </li>
        <li class="message_item">{{ t('395') }}: {{ routeData.cycleParamTime }}</li>
        <li class="message_item">
          {{ t('1190') }}: {{ routeData.routeEstimatedTime }}s
        </li>
        <li class="message_item">{{ t('725') }}: {{ routeData.heightMode }}</li>
        <li class="message_item">{{ t('1191') }}: {{ routeData.taskEndMode }}</li>
        <li class="message_item">
          {{ t('1192') }}: {{ routeData.missionLossMode }}
        </li>
        <li class="message_item">{{ t('1193') }}: {{ routeData.headingMode }}</li>
        <li :class="routeData.load!=null?'':'message_item'" class="flex">
          <span class="tts_left">{{ t('438') }}: </span>
          <span class="tts_text">
            <span
              :style="{ 'max-height': isShowOpen ? textHeight : '' }"
              ref="textContainer"
              :class="isOpen?'auto_hieght':'text_height'" >{{routeData.load}}</span>
            <p v-show="isShowOpen" class="open_text flex-fac" @click="openTts">{{isOpen? t('1194'):t('1195')}}<el-icon v-show="!isOpen"><ArrowDown /></el-icon><el-icon v-show="isOpen"><ArrowUp /></el-icon></p>
          </span>
        </li>
      </ul>
    </div>
    <div class="right_menu_box" id="hoverBox">
      <p class="overflow_text" :title="routeData.name">{{ routeData.name }}</p>
      <p>{{ t('73') }}：{{ hoverPointData.wayPointLongitude }}°</p>
      <p>{{ t('74') }}：{{ hoverPointData.wayPointLatitude }}°</p>
      <p>{{ t('839') }}：{{ hoverPointData.heading }}°</p>
      <p>
        {{ t('138') }}：{{
          hoverPointData.gimbalPitch ? hoverPointData.gimbalPitch : "-"
        }}°
      </p>
      <p>{{ t('244') }}：{{ hoverPointData.wayPointAltitude }}m</p>
      <p>{{ t('246') }}：{{ hoverPointData.wayPointSpeed }}m/s</p>
      <p>{{ t('1196') }}：{{ hoverPointData.takePhoto ? t("1197") : t("1198") }}</p>
    </div>
  </div>
</template>

<script setup>
import { t } from '../../../../languages';
import { inject, onMounted, reactive, toRefs, ref, nextTick } from "vue";
import ufi3d from "@/utils/ufi-cesium";
import { useTif } from "./useTif";
import dataRoute from "@/network/data_route";

const props = defineProps({
  previewType: {
    type: String,
    default: "",
  },
  previewId: {
    type: String,
    default: "",
  },
});

const Cesium = inject("Cesium");
let viewer;

//初始化地球
function initCesium() {
  const tiandituConSess = localStorage.getItem("tiandituCon");
  viewer = ufi3d.initCesium("cesiumContainer", {
    // 地形
    terrainProvider: setTerrain(),
  });
  /* 设置地形 */
  function setTerrain() {
    const mars3dTerrain = localStorage.getItem('mars3dTerrain')
    const terrainUrl = (mars3dTerrain == 'true') ? 'https://data.mars3d.cn/terrain' : window.g.terrainUrl
    return  new Cesium.CesiumTerrainProvider({
        url: terrainUrl
    })
  };
  // 需要带上，不然会加载默认地图
  viewer.imageryLayers.removeAll();
    if (tiandituConSess === "true") {
      // 天地图
      const subdomains = ["0", "1", "2", "3", "4", "5", "6", "7"];
      const layers = viewer.imageryLayers;
      layers.addImageryProvider(
        new Cesium.WebMapTileServiceImageryProvider({
          //影像底图
          url:
            "https://t{s}.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=" +
            "47421dd0051ada3957f279b484932789",
          subdomains: subdomains, //URL模板中用于{s}占位符的子域。如果该参数是单个字符串，则字符串中的每个字符都是一个子域。如果它是一个数组，数组中的每个元素都是一个子域
          layer: "tdtImgLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible", //使用谷歌的瓦片切片方式
          maximumLevel: 18,
          show: true,
        })
      );
      layers.addImageryProvider(
        new Cesium.WebMapTileServiceImageryProvider({
          //影像注记
          url:
            "https://t{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=" +
            "47421dd0051ada3957f279b484932789",
          subdomains: subdomains,
          layer: "tdtCiaLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible",
          maximumLevel: 18,
          show: true,
        })
      );
    } else {
      // 谷歌地图
      const layers = viewer.imageryLayers;
      layers.addImageryProvider(
        new Cesium.UrlTemplateImageryProvider({
          url: window.g.satelliteMapUrl + "/{z}/{x}/{y}.png",
          tilingScheme: new Cesium.WebMercatorTilingScheme(),
        })
      );
    }
  return viewer;
}
const {
  getAllLayers,
  changeLayarVisible,
  filterLayer,
  layerChange,
  zoomToLayer,
  state,
  layerFilterWord,
} = useTif(initCesium);

const { previewType, previewId } = toRefs(props);

function combineIconAndLabel(url, label, size, fontSize = 30) {
  // 创建画布对象
  let canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;

  let ctx = canvas.getContext("2d");

  let promise = new Cesium.Resource.fetchImage(url).then((image) => {
    // 异常判断
    try {
      ctx.drawImage(image, 0, 0);
    } catch (e) {
      console.log(e);
    }

    // 渲染字体
    // font属性设置顺序：font-style, font-variant, font-weight, font-size, line-height, font-family
    ctx.fillStyle = Cesium.Color.WHITE.toCssColorString();
    ctx.font = `bold ${fontSize}px Microsoft YaHei`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(label, size / 2, size / 2);

    return canvas;
  });
  return promise;
}

const routeData = ref({});
const hoverPointData = reactive({
  wayPointLongitude: "",
  wayPointLatitude: "",
  heading: "",
  gimbalPitch: "",
  wayPointAltitude: "",
  wayPointSpeed: "",
  takePhoto: "",
});
onMounted(async () => {
  getAllLayers();
  // 1、添加一个文本实体，show默认显示false
  // 2、mouseMove的时候失去到航点就把文本实体显示出来，更新实时位置
  // 3、否则隐藏
  if (previewType.value === "route") {
    // 航线预览
    const res = await dataRoute.routeDetails(previewId.value);
    if (res.resultStatus === true) {
      routeData.value = res.resultData;
    }
  } else {
    // 任务预览
    const res = await dataRoute.taskRouteDetail(previewId.value);
    if (res.resultStatus === true) {
      routeData.value = res.resultData;
    }
  }
   nextTick(()=>{
    showAllText();
  })
  const routeList = routeData.value.previewMissionList
    ? routeData.value.previewMissionList
    : [];
  const positionArr = [];
  for (let i = 0; i < routeList.length; i++) {
    const routePoint = routeList[i];
    const lng = routePoint.wayPointLongitude;
    const lat = routePoint.wayPointLatitude;
    const alt = routePoint.wayPointAltitude;
    positionArr.push(lng, lat, alt);

    let labelUrl = "";
    let pointColor = "";
    if (routePoint.takePhoto === false) {
      labelUrl = "label";
      pointColor = "#0090DA";
    } else {
      labelUrl = "label_green";
      pointColor = "#0FDA00";
    }
    const groundWirePosi = [lng, lat, alt, lng, lat, 0];

    const entity = viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(lng, lat, alt),
      billboard: {
        image: require("../../../../asset/img/routeLine/route_icon.png"),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        scale: 0.6,
        pixelOffset: new Cesium.Cartesian2(0, -10),
      },
      point: {
        color: Cesium.Color.fromCssColorString(pointColor),
        outlineColor: Cesium.Color.fromCssColorString("#ffffff"), //轮廓颜色
        outlineWidth: 3, //轮廓宽度
        pixelSize: 12,
      },
      polyline: {
        positions: Cesium.Cartesian3.fromDegreesArrayHeights(groundWirePosi),
        material: new Cesium.PolylineDashMaterialProperty({
          color: Cesium.Color.fromCssColorString("#ffffff"),
        }),
      },
      pointData: routePoint,
      type: "routePoint",
    });

    combineIconAndLabel(
      require("../../../../asset/img/" + labelUrl + ".png"),
      i + 1,
      64
    ).then((res) => {
      entity.billboard.image = res;
    });
  }
  if (viewer) {
    const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    const hoverBox = document.getElementById("hoverBox");
    handler.setInputAction((e) => {
      // 返回具有' primitive'属性的对象（是否拾取到实体）
      const entityDrag = viewer.scene.pick(e.endPosition); //选取当前的entity
      if (entityDrag && entityDrag.id && entityDrag.id.type === "routePoint") {
        const pointData = entityDrag.id.pointData;
        hoverPointData.wayPointLongitude = pointData.wayPointLongitude;
        hoverPointData.wayPointLatitude = pointData.wayPointLatitude;
        hoverPointData.heading = pointData.heading;
        hoverPointData.gimbalPitch = pointData.gimbalPitch;
        hoverPointData.wayPointAltitude = pointData.wayPointAltitude.toFixed(2);
        hoverPointData.wayPointSpeed = pointData.wayPointSpeed;
        hoverPointData.takePhoto = pointData.takePhoto;
        hoverBox.style.display = "block";
        hoverBox.style.transform = `translate(${e.endPosition.x}px, ${e.endPosition.y}px)`;
      } else {
        hoverBox.style.display = "none";
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }
  if (positionArr.length < 3) return;
  const entity = viewer.entities.add({
    polyline: {
      width: 4,
      positions: Cesium.Cartesian3.fromDegreesArrayHeights(positionArr),
      material: Cesium.Color.fromCssColorString("#0090DA"),
    },
    type: "routeLine",
  });

  viewer.flyTo(entity, {
    offset: new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-15), 200),
    duration: 2,
  });
});


const textContainer = ref(null)
const isOpen = ref(false);
const textHeight = ref('');
const isShowOpen = ref(false);
// 展开
function openTts() {
  isOpen.value = !isOpen.value;
}
function showAllText() {
  let twoHeight = 21 * 2;
  textHeight.value = `${twoHeight}px`;
  let curHeight = textContainer.value.offsetHeight;
  if (curHeight > twoHeight) {
    isShowOpen.value = true;
  } else {
    isShowOpen.value = false;
  }
}
</script>

<style lang="less" scoped>
#cesiumContainer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: black;
  .layer_div {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    z-index: 1;
    background: #0b476b;
    border-radius: 0.25rem;
  }
  .route_message {
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    max-width: 18.75rem;
    // height: 15.9375rem;
    z-index: 1;
    background: rgba(11, 71, 107, 0.9);
    border-radius: 0.25rem;
    .title {
      padding: 10px .9375rem;
      // text-align: center;
      border-bottom: 1px solid rgba(240, 240, 240, 0.6);
      .span-wid{
        min-width:5.125rem;
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: rgba(240, 240, 240, 0.6);
      margin-top: 0.5625rem;
    }
    .message_box {
      padding: 8px 15px;
      max-height: 38.75rem;
      overflow: hidden;
      overflow-y: auto;
      .message_item {
        line-height: 2;
      }
      .tts_left{
        width: 4.3125rem;
        justify-content: flex-start;
      }
      .tts_text{
        width: calc(100% - 4.3125rem);
        justify-content: flex-end;
      }
      .text_height{
        display: inline-block;
        overflow: hidden;
      }
      .auto_hieght{
        height: auto;
      }
      .open_text {
        cursor: pointer;
        color: #38adf9;
        .el-icon{
          color: #38adf9;
        }
      }
    }
  }
}
// 图层
.codeImg {
  width: 18.75rem;
  // height: 18.75rem;
  box-sizing: border-box;
  padding-top: 1.25rem;

  .elsearch {
    width: 100%;
    height: 2.25rem;
    padding: 0 0.875rem;
    :deep(.el-input__inner) {
      color: #fff !important;
    }
    // line-height: 2.25rem;
    .filePut {
      width: 13.25rem;
      height: 2.25rem;
      box-sizing: border-box;
      border: 0px solid #51d2ff;
      outline: 0;
      font-size: 0.875rem;
    }

    input::-webkit-input-placeholder {
      color: #c9c9c9;
    }

    .file_icon {
      width: 3.625rem;
      height: 2.25rem;
      color: #ffffff;
      background: #38adf9;
      border-radius: 0px 4px 4px 0px;
      font-weight: 500;
      font-size: 1.375rem;
    }

    /deep/.el-input {
      width: 100% !important;
    }

    /deep/.el-input__wrapper {
      width: 100% !important;
      border: 0px solid #51d2ff;
      box-shadow: 0 0 0 0;
      border-radius: 4px 0 0 4px;
      height: 2.25rem !important;
    }

    /deep/.el-input__inner {
      width: 100% !important;
      height: 2.25rem !important;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .laylist {
    height: 16.5625rem;
    overflow: hidden;
    overflow: auto;
    box-sizing: border-box;

    .itemTop {
      padding: 0 0.875rem;
      margin-top: 1.5rem;
      cursor: pointer;
      span {
        color: #00f5ff;
        font-size: 1rem;
      }
    }
    .itemTop:hover {
      background: #38adf9;
      span {
        color: #fff;
      }
    }
  }
}
.right_menu_box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 18.75rem;
  transform: translate(0px, 0px);
  /* width: 212px; */
  background: rgba(11, 71, 107, 0.85);
  z-index: 100;
  color: #fff;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.6;
  border-radius: 4px;
}
</style>