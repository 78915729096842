import { t } from '../languages';
import service from "./service";
import request from "./http.js";
const dataRoute = {
    // 新增航线管理
    addRoute: (data) => {
        return service.post({
            url: `/api/v1/routeInfo/import`,
            data
        })
    },
    //删除航线
    delRoute: (data) => {
        return service.delete({
            url: `/api/v1/delete/route`,
            data
        })
    },
    // 检查文件是否已上传
    inspectFile: (md5) => {
        return service.get({
            url: `/api/v1/file/route/${md5}`
        })
    },
    // 航线类型
    routeDownList: () => {
        return service.get({
            url: `/api/v1/show/route/downList`
        })
    },
    // 航线来源
    routeFromType: () => {
        return service.get({
            url: `/api/v1/show/route/downList/fromType`
        })
    },
    // 航线列表
    routeList: (data) => {
        return service.get({
            url: `/api/v1/show/route/list?pageNo=${data.pageNo}&pageSize=${data.pageSize}&queryInfo=${data.queryInfo}&fromType=${data.fromType}&routeType=${data.routeType}&startDate=${data.startDate}&endDate=${data.endDate}`
        })
    },
    // 单条航线数据
    routeIdList: (id) => {
        return service.get({
            url: `/api/v1/show/route/${id}`
        })
    },
    //单文件上传
    routeUpload: (data,
        isFormData) => {
        return service.post({
            url: `/api/v1/file/route/upload`,
            data,
            isFormData
        })
    },
    //新增优飞航线
    routeUploadUfi: (data,
        isFormData) => {
        return service.post({
            url: `/api/v1/add/route/yf`,
            data,
            isFormData
        })
    },
    //重命名
    routeEditName: (routeId, routeName) => {
        return request({
            url: `/api/v1/route/updName`,
            method: "put",
            params: {
                routeId: routeId,
                routeName: routeName
            }
        });
    },
    // 导出航线
    routeExportExl: (data) => {
        return service.post({
            url: `/api/v1/export/route/zip`,
            data
        })
    },
    //分配航线
    routeSetting: (data) => {
        return service.post({
            url: `/api/v1/settings/route`,
            data
        })
    },

    // 指挥中心航线
    getCenterRoute: (data) => {
        return service.get({
            url: `/api/v1/show/routePlanning/${data.sn}?routeName=${data.routeName}`
        })
    },
    // 预览航线
    previewRoute: (routeId) => {
        return request({
            url: `/api/v1/route/previewRoute`,
            method: "post",
            params: {
                id:routeId
            }
        });
    },
    //航线详情
    routeDetails: (routeId) => {
        return request({
            url: `/api/v1/route/routeDetails`,
            method: "post",
            params: {
                id:routeId
            }
        });
    },
    //任务航线预览
    taskPreviewRoute: (taskId) => {
        return request({
            url: `/api/v1/tasks/previewRoute`,
            method: "post",
            params: {
                taskId:taskId
            }
        });
    },
    //任务航线详情
    taskRouteDetail: (taskId) => {
        return request({
            url: `/api/v1/task/routeDetail`,
            method: "post",
            params: {
                taskId:taskId
            }
        });
    },
    // 获取安全飞行高度
    getTaskHitht:(nestSn)=>{
        return request({
            url:`/api/v1/getPoint/taskHitht/${nestSn}`
        })
    }
}
export default dataRoute